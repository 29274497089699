<div class="grid p-fluid">
  <div class="col-12">
    <div class="card card-w-title">
      <p-menubar [model]="tieredItems">
        <ng-template pTemplate="start">
          <img src="/assets/logo-engie-blue.svg" class="logo">
        </ng-template>
        <ng-template pTemplate="end">
          <span class="p-input-icon-right">
            <p-button label="Déconnexion" severity="danger" (click)="logout()"  icon="pi pi-fw pi-sign-out"></p-button>
          </span>
        </ng-template>
      </p-menubar>
    </div>
  </div>
</div>