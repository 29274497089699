import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { DatasetService } from '../services/dataset.service';
import { Copy } from '../api/copy';
import { Action, mapActionToLabel, mapStatusToLabel } from '../api/action';
import { MessageService } from 'primeng/api';
import { forkJoin } from 'rxjs';
import moment from 'moment';
import 'moment/locale/fr';
import { DataSetDetails } from '../api/datasetDetails';
import { OKTA_AUTH } from '@okta/okta-angular';
import OktaAuth, { CustomUserClaims, UserClaims } from '@okta/okta-auth-js';
import { UserService } from '../services/user.service';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrl: './home.component.scss',
  providers: [MessageService],
})
export class HomeComponent implements OnInit{
  copies: Copy[] = [];
  actions: Action[] = [];
  currentCopy: Copy = {};
  datasetConstructionDetails: DataSetDetails= {};
  datasetRampupDetails: DataSetDetails= {};
  loading = true;
  buttonTitle: string = ''
  user: UserClaims<CustomUserClaims> | undefined;
  sidebarVisible = false
  logs = []
  
  constructor(private router: Router, private cdr: ChangeDetectorRef, private datasetService: DatasetService, private messageService: MessageService, private userService: UserService, @Inject(OKTA_AUTH) private oktaAuth: OktaAuth){
    moment.locale('fr');
    this.buttonTitle = `Lancer la copie du mois de ${moment().locale('fr').local().subtract(1, 'months').format('MMMM YYYY')}`
  }
  
  async ngOnInit() {
    const user = await this.oktaAuth.getUser();
    this.user = user
    this.getDatas();
  }

  showLogs() {
    this.datasetService.getLogs().subscribe(
      {
        next: (res) => {
          this.logs = res.body
          this.sidebarVisible = true
        },
        error: this.handleError,
        complete: () => {
        }
      }
    )
  }
  
  getDatas() {
    const requests = [this.datasetService.listActions(), this.datasetService.datasetConstructionInformations(), this.datasetService.datasetRampupInformations()]
    forkJoin(requests).subscribe({
      next: (responses) => {
        const responseActionsBody: { actions: Action[] } = JSON.parse(responses[0].body);
        
        this.actions= responseActionsBody.actions?.map(action => ({
          ...action,
          action: mapActionToLabel(action.action || ''),
          month:moment(action.action_date, 'DD/MM/YYYY HH:mm:ss').locale('fr').local().subtract(1, 'months').format('MMMM YYYY'),
          status_label: mapStatusToLabel(action.status || ''),
          action_date: moment(action.action_date, 'DD/MM/YYYY HH:mm:ss').local().format('DD/MM/YYYY HH:mm:ss')
        }))
        
        this.actions = this.actions?.sort((a, b) => {
          // Utilisez moment pour parser les dates et les comparer
          const dateA = moment(a.action_date, 'DD/MM/YYYY HH:mm:ss');
          const dateB = moment(b.action_date, 'DD/MM/YYYY HH:mm:ss');
          
          // Comparer les dates
          return dateB.isBefore(dateA) ? -1 : dateB.isAfter(dateA) ? 1 : 0;
        });
        const responseDatasetConstructionInformationsBody: { dataset_informations: DataSetDetails } = JSON.parse(responses[1].body);
        this.datasetConstructionDetails = responseDatasetConstructionInformationsBody.dataset_informations;
        if(this.datasetConstructionDetails)
        this.datasetConstructionDetails.last_modified = moment(this.datasetConstructionDetails.last_modified).local().format('DD/MM/YYYY HH:mm')
        
        const responseDatasetRampupInformationsBody: { dataset_informations: DataSetDetails } = JSON.parse(responses[2].body);
        this.datasetRampupDetails = responseDatasetRampupInformationsBody.dataset_informations;
        if(this.datasetRampupDetails)
        this.datasetRampupDetails.last_modified = moment(this.datasetRampupDetails.last_modified).local().format('DD/MM/YYYY HH:mm')
        
      },
      error: this.handleError,
      complete: () => {
        this.loading = false;
      }
    });
  }
  
  //   load(index: number) {
  //     this.loading[index] = true;
  //     setTimeout(() => this.loading[index] = false, 1000);
  // }
  
  lunchCopy() {
    this.loading = true;
    const copy: Copy = {
      username: this.user?.preferred_username,
      copy_date: moment().local().format('DD/MM/YYYY HH:mm:ss'),
      cdh_project: environment.cdh_project,
      bucket_s3: environment.s3_bucket,
      bucket_construction_key: environment.bucket_construction_key,
      bucket_rampup_key: environment.bucket_rampup_key,
      athena_database: environment.cdh_project,
      athena_construction_table: environment.athena_construction_table_validated,
      athena_rampup_table:  environment.athena_rampup_table_validated,
      construction_filename:  environment.construction_filename,
      rampup_filename:  environment.rampup_filename
    }
    this.datasetService.lunchCopy(copy).subscribe(
      {
        next: this.handleResponse,
        error: this.handleError,
        complete: () => {
          this.getDatas();
          //window.location.reload();
        }
      }
      )
    }
    
    handleResponse = (res: any) => {
      if (res.statusCode == 200) {
        this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'La copie est lancée.', life: 3000 });
      } else {
        this.messageService.add({ severity: 'error', summary: 'Erreur', detail: `Une erreur est survenue.`, life: 3000 });
      }
    };
    
    handleResponseInvalidate = (res: any) => {
      if (res.statusCode == 200) {
        this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'La copie est invalidée.', life: 3000 });
      } else {
        this.messageService.add({ severity: 'error', summary: 'Erreur', detail: `Une erreur est survenue.`, life: 3000 });
      }
    };
    
    // Fonction pour gérer les erreurs
    handleError = (error: any) => {
      this.messageService.add({ severity: 'error', summary: 'Erreur', detail: `Une erreur est survenue.`, life: 3000 });
    };
    
  }
  