export interface Action {
  id?: string;
  action_date?: string;
  action?: string;
  username?: string;
  status?: string;
  status_label?: string;
  month?: string;
  construction_size_mb?: string;
  rampup_size_mb?: string;
  construction_line_count?: string;
  rampup_line_count?: string;
}

export function mapActionToLabel(action: string): string {
  switch (action) {
    case 'INVALID_COPY':
      return 'Invalidation d\'une copie';
    case 'LUNCH_COPY':
      return 'Lancement d\'une copie';
    default:
      return action; // Retourne la valeur originale si elle ne correspond à aucune des options spécifiées
  }
}

export function mapStatusToLabel(action: string): string {
  switch (action) {
    case 'SUCCESS':
      return 'Copie réussie';
    case 'FAILED':
      return 'Echec';
    default:
      return action; // Retourne la valeur originale si elle ne correspond à aucune des options spécifiées
  }
}