import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment.development';
import { Copy } from '../api/copy';

@Injectable({
  providedIn: 'root'
})
export class DatasetService {
  
  private header = new HttpHeaders({
    'Content-Type': 'application/json',
    'x-api-key': environment.apiKey,
  });
  private apiUrl = environment.apiURL;
  constructor(private http: HttpClient) { }
  
  lunchCopy(copy: Copy): Observable<any> {
    return this.http.post(`${this.apiUrl}/lunch_copy`, copy, {
      headers: this.header,
    });
  }

  getLogs(): Observable<any> {
    return this.http.get(`${this.apiUrl}/get_logs`, {
      headers: this.header,
    });
  }
  
  listActions(): Observable<any> {
    return this.http.get(`${this.apiUrl}/list_actions`, {
      headers: this.header,
    });
  }

  datasetConstructionInformations(): Observable<any> {
    return this.http.get(`${this.apiUrl}/dataset_informations?s3_bucket=${environment.s3_bucket}&s3_key=${environment.construction_s3_path}&athena_database=${environment.cdh_project}&athena_table=${environment.athena_construction_table_raw}`, {
      headers: this.header,
    });
  }

  datasetRampupInformations(): Observable<any> {
    return this.http.get(`${this.apiUrl}/dataset_informations?s3_bucket=${environment.s3_bucket}&s3_key=${environment.rampup_s3_path}&athena_database=${environment.cdh_project}&athena_table=${environment.athena_rampup_table_raw}`, {
      headers: this.header,
    });
  }
}
