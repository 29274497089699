import { ChangeDetectorRef, Component } from '@angular/core';
import { User } from '../../api/user';
import { UserService } from '../../services/user.service';
import { Table } from 'primeng/table';
import { MessageService } from 'primeng/api';
import { forkJoin } from 'rxjs';
import { error } from 'console';
import { Router } from '@angular/router';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  providers: [MessageService],
  styleUrl: './user-list.component.scss'
})
export class UserListComponent {
  users: User[] = [];
  user: User = {};
  selectedUsers: User[] = [];
  userDialog: boolean = false;
  submitted: boolean = false;
  deleteUsersDialog: boolean = false;
  deleteUserDialog: boolean = false;
  
  constructor(private router: Router, private userService: UserService, private messageService: MessageService){}
  
  ngOnInit() {
    this.getUsers();
  }
  
  getUsers() {
    this.users = []
    this.userService.getUsers().subscribe(
      (res) => {
        const responseBody: { users: User[] } = JSON.parse(res.body);
        this.users= responseBody.users
      }
      );
    }
    
    onGlobalFilter(table: Table, event: Event) {
      table.filterGlobal((event.target as HTMLInputElement).value, 'contains');
    }
    
    openNew() {
      this.user = {};
      this.submitted = false;
      this.userDialog = true;
    }
    
    editUser(user: User) {
      this.user = { ...user };
      this.userDialog = true;
    }
    
    deleteUser(user: User) {
      this.deleteUserDialog = true;
      this.user = { ...user };
    }
    
    confirmDelete() {
      this.deleteUserDialog = false;
      this.userService.deleteUser(this.user).subscribe(
        {
          next: (responses) => {
            this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Utilisateur supprimé', life: 3000 });
            this.user = {};
          },
          error: this.handleError,
          complete: () => {
            this.getUsers();
            location.reload();
          }
        }
        )
      }
      
      confirmDeleteSelected() {
        this.deleteUsersDialog = false;
        const deleteRequests = this.selectedUsers.map(user => this.userService.deleteUser(user));
        forkJoin(deleteRequests).subscribe({
          next: (responses) => {
            this.messageService.add({ severity: 'success', summary: 'Succès', detail: 'Utilisateurs supprimés', life: 3000 });
            this.selectedUsers = [];
          },
          error: this.handleError,
          complete: () => {
            this.getUsers();
            location.reload();
          }
        }
        );
      }
      
      hideDialog() {
        this.userDialog = false;
        this.submitted = false;
      }
      
      deleteSelectedUsers() {
        this.deleteUsersDialog = true;
      }
      
      handleResponse = (res: any) => {
        if (res.statusCode == 200) {
          this.hideDialog();
          this.user = {};
          this.messageService.add({ severity: 'success', summary: 'Succès', detail: this.user.username ? 'Utilisateur mis à jour.' : 'Utilisateur ajouté.', life: 3000 });
          location.reload();
        } else if (res.statusCode == 400) {
          this.messageService.add({ severity: 'error', summary: 'Erreur', detail: `L'utilisateur existe déjà.`, life: 3000 });
        } else {
          this.messageService.add({ severity: 'error', summary: 'Erreur', detail: `Une erreur est survenue.`, life: 3000 });
        }
      };
      
      // Fonction pour gérer les erreurs
      handleError = (error: any) => {
        this.messageService.add({ severity: 'error', summary: 'Erreur', detail: `Une erreur est survenue.`, life: 3000 });
      };
      
      
      saveUser() {
        this.submitted = true;
        if (this.user.username) {
          this.userService.updateUser(this.user).subscribe(
            {
              next: this.handleResponse,
              error: this.handleError,
              complete: () => {
                this.getUsers();
              }
            }
            )
          } else {
            this.userService.createUser(this.user).subscribe(
              {
                next: this.handleResponse,
                error: this.handleError,
                complete: () => {
                  this.getUsers();
                }
              }
              )
            }
          }
          
        }
        