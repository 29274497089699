<app-navbar></app-navbar>
<p-toast></p-toast>
<p-toolbar styleClass="mb-4">
  <ng-template pTemplate="left">
    <p-button label="Ajouter" icon="pi pi-plus" class="mr-2" severity="success" (click)="openNew()"></p-button>
    <p-button label="Supprimer" icon="pi pi-trash" (click)="deleteSelectedUsers()" severity="danger" [disabled]="!selectedUsers || !selectedUsers.length"></p-button>
  </ng-template>
</p-toolbar>
<p-table #dt [value]="users" responsiveLayout="scroll" [rows]="50" [globalFilterFields]="['firstname','lastname','gid','email','username']" [paginator]="true" [showCurrentPageReport]="true" currentPageReportTemplate="Affichage de {first} à {last} sur {totalRecords} entrées" [(selection)]="selectedUsers" selectionMode="multiple" [rowHover]="true" dataKey="gid">
  <ng-template pTemplate="caption">
    <div class="flex flex-col md:flex-row md:justify-between md:items-center">
      <h5 class="m-0">Gestion des utilisateurs</h5>
      <span class="block mt-2 md:mt-0 p-input-icon-left">
        <i class="pi pi-search"></i>
        <input pInputText type="text" (input)="onGlobalFilter(dt, $event)" placeholder="Search..."  class="w-full sm:w-auto"/>
      </span>
    </div>
  </ng-template>
  <ng-template pTemplate="header">
    <tr>
      <th style="width: 3rem">
        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
      </th>
      <th pSortableColumn="gid" style="width:25%">GID <p-sortIcon field="gid"></p-sortIcon></th>
      <th pSortableColumn="firstname" style="width:25%">Nom <p-sortIcon field="firstname"></p-sortIcon></th>
      <th pSortableColumn="lastname" style="width:25%">Prénom <p-sortIcon field="lastname"></p-sortIcon></th>
      <th pSortableColumn="email" style="width:25%">Email <p-sortIcon field="email"></p-sortIcon></th>
      <th pSortableColumn="username" style="width:25%">Nom d'utilisateur <p-sortIcon field="username"></p-sortIcon></th>
      <th>Actions</th>
    </tr>
  </ng-template>
  <ng-template pTemplate="body" let-user>
    <tr>
      <td>
        <p-tableCheckbox [value]="user"></p-tableCheckbox>
      </td>
      <td>{{ user.gid }}</td>
      <td>{{ user.firstname }}</td>
      <td>{{ user.lastname }}</td>
      <td>{{ user.email }}</td>
      <td>{{ user.username }}</td>
      <td>
        <div class="flex">
          <p-button icon="pi pi-pencil" severity="success" (click)="editUser(user)"></p-button>
          <p-button icon="pi pi-trash" severity="warning" class="ml-2" (click)="deleteUser(user)"></p-button>
        </div>
      </td>
    </tr>
  </ng-template>
</p-table>

<p-dialog [(visible)]="userDialog" [style]="{width: '450px'}" header="Détails utilisateur" [modal]="true" class="p-fluid">
  <ng-template pTemplate="content">
    <div class="field">
      <label for="firstname">Nom</label>
      <input type="text" pInputText id="firstname" [(ngModel)]="user.firstname" required autofocus [ngClass]="{'ng-invalid ng-dirty' : submitted && !user.firstname}"/>
      <small class="ng-dirty ng-invalid" *ngIf="submitted && !user.firstname">Le nom est requis.</small>
    </div>
    <div class="field">
      <label for="firstname">Prénom</label>
      <input type="text" pInputText id="lastname" [(ngModel)]="user.lastname" required autofocus [ngClass]="{'ng-invalid ng-dirty' : submitted && !user.lastname}"/>
      <small class="ng-dirty ng-invalid" *ngIf="submitted && !user.lastname">Le prénom est requis.</small>
    </div>
    <div class="field">
      <label for="email">Email</label>
      <input type="text" pInputText id="email" [(ngModel)]="user.email" required autofocus [ngClass]="{'ng-invalid ng-dirty' : submitted && !user.email}"/>
      <small class="ng-dirty ng-invalid" *ngIf="submitted && !user.email">L'adresse e-mail est requise.</small>
    </div>
    <div class="field">
      <label for="gid">GID</label>
      <input type="text" pInputText id="gid" [disabled]="user.gid ? true : false" [(ngModel)]="user.gid" required autofocus [ngClass]="{'ng-invalid ng-dirty' : submitted && !user.gid}"/>
      <small class="ng-dirty ng-invalid" *ngIf="submitted && !user.gid">Le GID est requis.</small>
    </div>
  </ng-template>
  
  <ng-template pTemplate="footer">
    <p-button label="Annuler" icon="pi pi-times" class="p-button-text" (click)="hideDialog()"></p-button>
    <p-button label="Sauvegarder" icon="pi pi-check" class="p-button-text mt-4" (click)="saveUser()"></p-button>
  </ng-template>
</p-dialog>

<p-dialog [(visible)]="deleteUserDialog" header="Confirm" [modal]="true" [style]="{width:'450px'}">
  <div class="flex align-items-center justify-content-center">
    <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"></i>
    <span *ngIf="user">Êtes vous sûr de vouloir supprimer l'utilisateur <b>{{user.firstname}} {{user.lastname}}</b> ?</span>
  </div>
  <ng-template pTemplate="footer">
    <button pButton pRipple icon="pi pi-times" class="p-button-text" label="No" (click)="deleteUserDialog = false"></button>
    <button pButton pRipple icon="pi pi-check" class="p-button-text" label="Yes" (click)="confirmDelete()"></button>
  </ng-template>
</p-dialog>

<p-dialog [(visible)]="deleteUsersDialog" header="Confirm" [modal]="true" [style]="{width:'450px'}">
  <div class="flex align-items-center justify-content-center">
    <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem"></i>
    <span>Êtes vous sûr de vouloir supprimer les utilisateurs séléctionnés ?</span>
  </div>
  <ng-template pTemplate="footer">
    <button pButton pRipple icon="pi pi-times" class="p-button-text" label="No" (click)="deleteUsersDialog = false"></button>
    <button pButton pRipple icon="pi pi-check" class="p-button-text" label="Yes" (click)="confirmDeleteSelected()"></button>
  </ng-template>
</p-dialog>