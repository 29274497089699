import { Component, Inject } from '@angular/core';
import { OktaAuthStateService, OKTA_AUTH } from '@okta/okta-angular';
import OktaAuth from '@okta/okta-auth-js';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrl: './navbar.component.scss',
  styles: [`
  :host ::ng-deep .p-menubar-root-list {
    flex-wrap: wrap;
  }
  `]
})
export class NavbarComponent {
  tieredItems: MenuItem[] = [];
  constructor(public authStateService: OktaAuthStateService, @Inject(OKTA_AUTH) private oktaAuth: OktaAuth) {

  }
  ngOnInit() {
    this.tieredItems = [
      {
        label: 'Accueil',
        icon: 'pi pi-fw pi-home',
        url: '/',
        target: '_self'
      },
      {
        label: 'Utilisateurs',
        icon: 'pi pi-fw pi-users',
        url: 'users',
        target: '_self'
      }
    ];
  }

  async logout() {
    await this.oktaAuth.signOut();
  }
}
