import { Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OKTA_AUTH } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { UserService } from './services/user.service';
import { MessageService } from 'primeng/api';
import { environment } from '../environments/environment.development';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent implements OnInit{
  title = 'cdh-rgbu-pmo-copy-dataset';
  user: string = '';
  constructor(private router: Router, private userService: UserService, private messageService: MessageService, @Inject(OKTA_AUTH) private oktaAuth: OktaAuth) {}

  async ngOnInit() {
    const user = await this.oktaAuth.getUser();
    this.user = JSON.stringify(user, null, 4);
    if(user.preferred_username) {
      this.userService.getUser(user.preferred_username).subscribe(
        {
          next: (responses) => {
            if(responses.statusCode == 200) {
              if(JSON.parse(responses.body).users == 0) {
                this.router.navigate(['unauthorized'])
              }
            }else {
              this.router.navigate(['unauthorized'])
            }
          },
          error: () => {
            this.messageService.add({ severity: 'error', summary: 'Erreur', detail: `Une erreur est survenue.`, life: 3000 });
            this.router.navigate(['unauthorized'])
          },
          complete: () => {
          }
        }
      )
    }
  }
}
