import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { User } from '../api/user';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment.development';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  private header = new HttpHeaders({
    'Content-Type': 'application/json',
    'x-api-key': environment.apiKey,
  });
  private apiUrl = environment.apiURL;
  constructor(private http: HttpClient) { }
  
  getUsers(): Observable<any> {
    return this.http.get(`${this.apiUrl}/list_users`, {
      headers: this.header,
    });
  }

  getUser(username: string): Observable<any> {
    return this.http.get(`${this.apiUrl}/get_user?username=${username}`, {
      headers: this.header,
    });
  }
  updateUser(user: User): Observable<any> {
    return this.http.post(`${this.apiUrl}/update_user`, user, {
      headers: this.header,
    });
  }
  createUser(user: User): Observable<any> {
    return this.http.post(`${this.apiUrl}/create_user`, user,{
      headers: this.header,
    });
  }
  deleteUser(user: User): Observable<any> {
    return this.http.post(`${this.apiUrl}/delete_user?gid=${user.gid}`,{}, {
      headers: this.header,
    });
  }
  
}
