<app-navbar></app-navbar>
<p-toast></p-toast>
<p-sidebar [(visible)]="sidebarVisible" [fullScreen]="true" [baseZIndex]="10000">
  <h3 style="font-weight:normal">Logs</h3>
  <div *ngFor="let log of logs">
    <p>{{log}}</p>
  </div>
</p-sidebar>
<div class="p-8 w-full h-full">
  <p>Information sur le dataset GROW Contruction</p>
  <div *ngIf="loading" class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-8 my-8">
    <p-skeleton class="rounded-xl h-24 p-4 border-2 border-blue-300">
      <p class="font-medium text-lg">Nom</p>
      <p class="font-medium text-sm">RGBU_PMO_GROW_ConstructProject_NoProd</p>
    </p-skeleton>
    <p-skeleton class="rounded-xl h-24 p-4 border-2 border-blue-300">
      <p class="font-medium text-lg">Date de dernière mise à jour</p>
      <p class="font-medium text-sm">{{datasetConstructionDetails ? datasetConstructionDetails.last_modified : ''}}</p>
    </p-skeleton>
    <p-skeleton class="rounded-xl h-24 p-4 border-2 border-blue-300">
      <p class="font-medium text-lg">Taille</p>
      <p class="font-medium text-sm">{{datasetConstructionDetails ? datasetConstructionDetails.file_size : ''}} Mb</p>
    </p-skeleton>
    <p-skeleton class="rounded-xl h-24 p-4 border-2 border-blue-300">
      <p class="font-medium text-lg">Nombre de lignes</p>
      <p class="font-medium text-sm">{{datasetConstructionDetails ? datasetConstructionDetails.line_count : ''}}</p>
    </p-skeleton>
  </div>
  <div *ngIf="!loading" class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-8 my-8">
    <div class="rounded-xl h-24 p-4 border-2 border-blue-300">
      <p class="font-medium text-lg">Nom</p>
      <p class="font-medium text-sm">RGBU_PMO_GROW_ConstructProject_NoProd</p>
    </div>
    <div class="rounded-xl h-24 p-4 border-2 border-blue-300">
      <p class="font-medium text-lg">Date de dernière mise à jour</p>
      <p class="font-medium text-sm">{{datasetConstructionDetails ? datasetConstructionDetails.last_modified : ''}}</p>
    </div>
    <div class="rounded-xl h-24 p-4 border-2 border-blue-300">
      <p class="font-medium text-lg">Taille</p>
      <p class="font-medium text-sm">{{datasetConstructionDetails ? datasetConstructionDetails.file_size : ''}} Mb</p>
    </div>
    <div class="rounded-xl h-24 p-4 border-2 border-blue-300">
      <p class="font-medium text-lg">Nombre de lignes</p>
      <p class="font-medium text-sm">{{datasetConstructionDetails ? datasetConstructionDetails.line_count : ''}}</p>
    </div>
  </div>

  <p>Information sur le dataset GROW Rampup</p>
  <div *ngIf="loading" class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-8 my-8">
    <p-skeleton class="rounded-xl h-24 p-4 border-2 border-blue-300">
      <p class="font-medium text-lg">Nom</p>
      <p class="font-medium text-sm">RGBU_PMO_GROW_CapacityRampUp_NoProd</p>
    </p-skeleton>
    <p-skeleton class="rounded-xl h-24 p-4 border-2 border-blue-300">
      <p class="font-medium text-lg">Date de dernière mise à jour</p>
      <p class="font-medium text-sm">{{datasetRampupDetails ? datasetRampupDetails.last_modified : ''}}</p>
    </p-skeleton>
    <p-skeleton class="rounded-xl h-24 p-4 border-2 border-blue-300">
      <p class="font-medium text-lg">Taille</p>
      <p class="font-medium text-sm">{{datasetRampupDetails ? datasetRampupDetails.file_size : ''}} Mb</p>
    </p-skeleton>
    <p-skeleton class="rounded-xl h-24 p-4 border-2 border-blue-300">
      <p class="font-medium text-lg">Nombre de lignes</p>
      <p class="font-medium text-sm">{{datasetRampupDetails ? datasetRampupDetails.line_count : ''}}</p>
    </p-skeleton>
  </div>
  <div *ngIf="!loading" class="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-8 my-8">
    <div class="rounded-xl h-24 p-4 border-2 border-blue-300">
      <p class="font-medium text-lg">Nom</p>
      <p class="font-medium text-sm">RGBU_PMO_GROW_CapacityRampUp_NoProd</p>
    </div>
    <div class="rounded-xl h-24 p-4 border-2 border-blue-300">
      <p class="font-medium text-lg">Date de dernière mise à jour</p>
      <p class="font-medium text-sm">{{datasetRampupDetails ? datasetRampupDetails.last_modified : ''}}</p>
    </div>
    <div class="rounded-xl h-24 p-4 border-2 border-blue-300">
      <p class="font-medium text-lg">Taille</p>
      <p class="font-medium text-sm">{{datasetRampupDetails ? datasetRampupDetails.file_size : ''}} Mb</p>
    </div>
    <div class="rounded-xl h-24 p-4 border-2 border-blue-300">
      <p class="font-medium text-lg">Nombre de lignes</p>
      <p class="font-medium text-sm">{{datasetRampupDetails ? datasetRampupDetails.line_count : ''}}</p>
    </div>
  </div>

  <p-button [label]="buttonTitle" [disabled]="loading" [loading]="loading" icon="pi pi-copy" severity="success" class="mr-2" (click)="lunchCopy()"></p-button>
  <p-button label="Logs" [disabled]="loading" (click)="showLogs()" [loading]="loading" icon="pi pi-info-circle" severity="success" class="mr-2"></p-button>
  <div class="p-4 border-2 border-blue-300 rounded-xl my-8">
    <p class="my-8 font-medium">Actions</p>
    <p-table #dt [value]="actions" responsiveLayout="scroll" [rows]="10" [globalFilterFields]="['action_date', 'month','status','username']" [paginator]="true" [showCurrentPageReport]="true" currentPageReportTemplate="Affichage de {first} à {last} sur {totalRecords} entrées" [rowHover]="true">
      <ng-template pTemplate="header">
        <tr>
          <th pSortableColumn="username" >Nom d'utilisateur <p-sortIcon field="username"></p-sortIcon></th>
          <th pSortableColumn="action">Action</th>
          <th pSortableColumn="action_date">Date <p-sortIcon field="action_date"></p-sortIcon></th>
          <th pSortableColumn="month">Mois <p-sortIcon field="month"></p-sortIcon></th>
          <th pSortableColumn="construction_size_mb">Taille v-construction</th>
          <th pSortableColumn="rampup_size_mb">Taille v-rampup</th>
          <th pSortableColumn="construction_line_count">Nbr l-construction</th>
          <th pSortableColumn="rampup_line_count">Nbr l-rampup</th>
          <th pSortableColumn="status">Statut <p-sortIcon field="status"></p-sortIcon></th>
        </tr>
      </ng-template>
      <ng-template pTemplate="body" let-action>
        <tr>
          <td>{{ action.username }}</td>
          <td>{{ action.action }}</td>
          <td>{{ action.action_date }}</td>
          <td>{{ action.month }}</td>
          <td>{{ action.construction_size_mb }} Mb</td>
          <td>{{ action.rampup_size_mb }} Mb</td>
          <td>{{ action.construction_line_count }}</td>
          <td>{{ action.rampup_line_count }}</td>
          <td><p-tag [severity]="action.status == 'SUCCESS' ? 'success' : 'danger'" [value]="action.status_label"></p-tag></td>
        </tr>
      </ng-template>
    </p-table>
  </div>
</div>
