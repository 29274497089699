import { APP_INITIALIZER, Injector, NgModule } from '@angular/core';
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TableModule } from 'primeng/table';
import { FileUploadModule } from 'primeng/fileupload';
import { ButtonModule } from 'primeng/button';
import { RippleModule } from 'primeng/ripple';
import { ToastModule } from 'primeng/toast';
import { FormsModule } from '@angular/forms';
import { ToolbarModule } from 'primeng/toolbar';
import { RatingModule } from 'primeng/rating';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { DropdownModule } from 'primeng/dropdown';
import { RadioButtonModule } from 'primeng/radiobutton';
import { InputNumberModule } from 'primeng/inputnumber';
import { DialogModule } from 'primeng/dialog';
import { HomeComponent } from './home/home.component';
import { UserListComponent } from './pages/user-list/user-list.component';
import { CardModule } from 'primeng/card';
import { NavbarComponent } from './components/navbar/navbar.component';
import { MenuModule } from 'primeng/menu';
import { MenubarModule } from 'primeng/menubar';
import { TagModule } from 'primeng/tag';
import { SkeletonModule } from 'primeng/skeleton';
import { SidebarModule } from 'primeng/sidebar';
import {
  OktaAuthModule,
  OktaConfig
} from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { environment } from '../environments/environment.development';
import { UnauthorizedComponent } from './pages/unauthorized/unauthorized.component';
import { MessageService } from 'primeng/api';
import { LogsComponent } from './pages/logs/logs.component';

const authConfig = {
  issuer: environment.oktaIssuer,
  clientId: environment.oktaClientId,
  redirectUri: '/login/callback',
  scopes: ['openid', 'profile', 'email'],
  pkce: true,
  testing: {
    disableHttpsCheck: true
  }
}
const oktaAuth = new OktaAuth(authConfig);
const moduleConfig: OktaConfig = { oktaAuth };

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    UserListComponent,
    NavbarComponent,
    UnauthorizedComponent,
    LogsComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    TagModule,
    BrowserAnimationsModule,
    OktaAuthModule.forRoot(moduleConfig),
    ButtonModule,
    TableModule,
    SidebarModule,
    FileUploadModule,
    MenuModule,
    MenubarModule,
    FormsModule,
    RippleModule,
    SkeletonModule,
    ToastModule,
    ToolbarModule,
    RatingModule,
    InputTextModule,
    CardModule,
    InputTextareaModule,
    DropdownModule,
    RadioButtonModule,
    InputNumberModule,
    DialogModule
  ],
  providers: [
    provideClientHydration(),
    MessageService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
