export const environment = {
  production: false,
  apiURL: 'https://cdh-rgbupmogrowdataprocess-85320.noprod.myfrren.com',
  apiKey: 'q53yJxY4G998ixlZB3Ly190zYiD7xKS455Ynvy5D',
  oktaIssuer: 'https://engieapppreview.oktapreview.com/oauth2/default',
  oktaClientId: '0oa7bo2psgN7pXd6T0x7',
  admins_users: ['fk6213@engie.com'],
  cdh_project: "cdh_rgbupmogrowdataprocess_85320",
  s3_bucket:"cdh-rgbugrownoprod-568535",
  bucket_construction_key: "rgbu_pmo_grow_constructproject_noprod",
  bucket_rampup_key: "rgbu_pmo_grow_capacityrampup_noprod",
  athena_construction_table_raw: "rgbu_pmo_grow_constructproject_noprod_raw",
  athena_rampup_table_raw:  "rgbu_pmo_grow_capacityrampup_noprod_raw",
  athena_construction_table_validated: "rgbu_pmo_grow_constructproject_noprod_validated",
  athena_rampup_table_validated:  "rgbu_pmo_grow_capacityrampup_noprod_validated",
  construction_s3_path:"rgbu_pmo_grow_constructproject_noprod/raw/data.snappy",
  rampup_s3_path:"rgbu_pmo_grow_capacityrampup_noprod/raw/data.snappy",
  construction_filename:"data.snappy",
  rampup_filename:"data.snappy",
};
