import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { UserListComponent } from './pages/user-list/user-list.component';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { OktaAuthGuard, OktaCallbackComponent } from '@okta/okta-angular';
import { UnauthorizedComponent } from './pages/unauthorized/unauthorized.component';
import { LogsComponent } from './pages/logs/logs.component';

const routes: Routes = [
  {
    path: '',
    component: AppComponent,
    canActivate: [ OktaAuthGuard ],
    children: [
      {
        path: 'users',
        component: UserListComponent,
        canActivate: [ OktaAuthGuard ],
      },
      {
        path: 'logs',
        component: LogsComponent,
        canActivate: [ OktaAuthGuard ],
      },
      {
        path: '',
        component: HomeComponent,
        canActivate: [ OktaAuthGuard ],
      },
    ]
  },
  {
    path: 'login/callback',
    component: OktaCallbackComponent
  },
  {
    path: 'unauthorized',
    component: UnauthorizedComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
